import { useState, useCallback, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import {
  AppTooltip, Button, SvgIcon, TipModal, Toast,
} from '@/modules/shared/components';
import { useAddEmail/* , useConfirmPassword */ } from '@/modules/me';
import { useMatchMedia } from '@/modules/shared/hooks';
import { AddEmailModal, ConfirmEmailModal, MobileConfirmedModal } from './modals';
import s from './field.module.scss';

type EmailProps = {
  email: string | null | undefined;
  refetch: () => void;
  emailVerified: boolean;
  isCreateCompanyPage?: boolean;
};

export const Email = ({
  email, refetch, emailVerified, isCreateCompanyPage = false,
}: EmailProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false);
  // раскомментировать здесь и ниже, когда будет готова возможность запроса пароля для всех случаев
  // const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isVerifiedToastOpen, setIsVerifiedToastOpen] = useState(false);
  const [verifiedToastText, setVerifiedToastText] = useState('');
  const [isConfirmedMobileOpen, setIsConfirmedMobileOpen] = useState(false);
  const {
    mutate: resendActivationLink, isSuccess, error, isError,
  } = useAddEmail();
  /* const {
    mutate: confirmPassword, isSuccess: isConfirmPasswordSuccess,
  } = useConfirmPassword(); */

  /* const handleConfirmPassword = useCallback((psw: string) => {
    confirmPassword(psw);
  }, [confirmPassword]);

  useEffect(() => {
    if (isConfirmPasswordSuccess) {
      setIsConfirmPasswordModalOpen(false);
      setIsAddEmailModalOpen(true);
    }
  }, [isConfirmPasswordSuccess]);

  const onConfirmPassword = useCallback(() => setIsConfirmPasswordModalOpen(true), []);
  const onCloseConfirmPassword = useCallback(() => setIsConfirmPasswordModalOpen(false), []); */

  const onCloseEmailModal = useCallback(() => {
    setIsAddEmailModalOpen(false);
    router.replace(router.pathname, undefined, { shallow: true });
  }, [router]);

  const onCloseConfirmEmailModal = useCallback(() => {
    setIsConfirmEmailModalOpen(false);
    refetch();
  }, []);

  const toggleModal = useCallback(() => setIsTipModalOpen((prev) => !prev), []);

  const onEmailContinue = useCallback((emailTemp: string) => {
    onCloseEmailModal();
    setTempEmail(emailTemp);
    setIsConfirmEmailModalOpen(true);
  }, []);

  const handleChangeEmail = useCallback(() => {
    setIsConfirmEmailModalOpen(false);
    setIsAddEmailModalOpen(true);
  }, []);

  const handleResendEmailVerification = useCallback(() => {
    resendActivationLink(email ?? tempEmail);
  }, [email, tempEmail]);

  const closeMobileConfirmationModal = useCallback(() => {
    if (isDesktop) return;
    setIsConfirmedMobileOpen(false);
    router.replace(router.pathname, undefined, { shallow: true });
  }, [router, isDesktop]);

  const closeVerifiedToast = useCallback(() => {
    setIsVerifiedToastOpen(false);
    router.replace(router.pathname, undefined, { shallow: true });
  }, [router]);

  useEffect(() => {
    if (router.query?.email === 'add') {
      setIsAddEmailModalOpen(true);
      return;
    }

    if (router.query?.action === '400_token_is_invalid_or_expired') {
      setIsVerifiedToastOpen(true);
      setVerifiedToastText(t('userAccount:link_expired'));
      return;
    }

    if (router.query?.action === 'email_verified') {
      if (isDesktop) {
        setIsVerifiedToastOpen(true);
        setVerifiedToastText(`${t('userAccount:email_confirmed')}. ${t('userAccount:email_added')}`);
      } else {
        setIsConfirmedMobileOpen(true);
      }
    }
  }, [router, isDesktop, t]);

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      if (error.response?.data?.code?.includes('THROTTLED')) {
        toast.error(t('forms:validationErrors.throttled'));
      }
    }

    if (isSuccess && !emailVerified) {
      setIsToastOpen(true);
    }
  }, [isSuccess, error, isError, emailVerified]);

  return (
    <>
      <div className={clsx(s.contact_item, {
        [s.email_align]: !email,
        [s.contact_item__create_page]: isCreateCompanyPage,
      })}
      >
        <p className={clsx(s.contact_header, {
          [s.contact_header__create_page]: isCreateCompanyPage,
        })}
        >
          E-mail
          {!email && !isCreateCompanyPage && (
            <AppTooltip title={t('common:emailNotification.tooltip')}>
              <span className={s.alert_svg}>
                <SvgIcon name="alert" />
              </span>
            </AppTooltip>
          )}
        </p>
        <div className={s.email_contact_wrapper}>
          {email ? (
            <div className={clsx(s.email_contact, {
              [s.contact_verified]: emailVerified,
            })}
            >
              <div className={clsx(s.input_view, s.input_view_contacts, {
                [s.phone]: !emailVerified,
                [s.input_view__create_page]: isCreateCompanyPage,
              })}
              >
                <SvgIcon name="mail" />
                <span className={s.email_value}>{email}</span>
                {!emailVerified && (
                  <span
                    className={s.edit_icon}
                    onClick={() => setIsAddEmailModalOpen(true)/* onConfirmPassword */}
                  >
                    <SvgIcon name="pencil" />
                  </span>
                )}
              </div>
              {emailVerified && (
                (isMobile || isTablet) ? (
                  <Button
                    appearance="secondary"
                    buttonColor="blue"
                    buttonSize="xs"
                    onClick={toggleModal}
                  >
                    <SvgIcon name="check" className={s.check_svg} />
                  </Button>
                ) : (
                  <AppTooltip title={t('userAccount:email_confirmed')} stylesTooltip="gray">
                    <span>
                      <SvgIcon name="check" className={s.check_svg} />
                    </span>
                  </AppTooltip>
                )
              )}
              {!emailVerified && (
                <div className={s.email_tips}>
                  <p className={s.error}>{t('userAccount:email_verification')}</p>
                  <Button
                    appearance="secondary"
                    buttonColor="green"
                    buttonSize="xs"
                    onClick={handleResendEmailVerification}
                  >
                    {t('userAccount:email_confirm_resend')}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Button
              appearance="secondary"
              buttonColor="white"
              buttonSize="s"
              onClick={() => setIsAddEmailModalOpen(true)/* onConfirmPassword */}
              className={clsx(s.add_contact, {
                [s.add_contact__create_page]: isCreateCompanyPage,
              })}
            >
              <SvgIcon name="plus" />
              {t('userAccount:add_email')}
            </Button>
          )}
          {isCreateCompanyPage && (
            <p className={s.tip}>{t('userAccount:become_company.verification.tip')}</p>
          )}
        </div>
      </div>
      {/* <ConfirmPasswordModal
        isOpen={isConfirmPasswordModalOpen}
        onClose={onCloseConfirmPassword}
        onClick={handleConfirmPassword}
        mode="email"
      /> */}
      <AddEmailModal
        isOpen={isAddEmailModalOpen}
        onClose={onCloseEmailModal}
        onContinue={onEmailContinue}
      />
      <ConfirmEmailModal
        isOpen={isConfirmEmailModalOpen && !emailVerified}
        onClose={onCloseConfirmEmailModal}
        email={tempEmail}
        onClick={handleResendEmailVerification}
        handleChangeEmail={handleChangeEmail}
      />
      <MobileConfirmedModal
        isOpen={isConfirmedMobileOpen && !isDesktop}
        header={t('userAccount:email_confirmed')}
        text={t('userAccount:email_added')}
        onClose={closeMobileConfirmationModal}
      />
      <TipModal
        isOpen={isTipModalOpen}
        content={t('userAccount:email_confirmed')}
        onClose={toggleModal}
      />
      {isToastOpen && (
        <Toast
          label={t('common:email_confirm_sent')}
          iconName="mail"
          onClose={() => setIsToastOpen(false)}
          className={s.toast}
        />
      )}
      {isVerifiedToastOpen && (
        <Toast
          label={verifiedToastText}
          iconName="mail"
          onClose={closeVerifiedToast}
          className={s.toast}
        />
      )}
    </>
  );
};
