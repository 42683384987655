import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import {
  AppTooltip, Button, SvgIcon, TipModal,
} from '@/modules/shared/components';
import { formatPhoneNumber, getDeclension } from '@/modules/shared/helpers';
import { /* useConfirmPassword, */ useGetVerificationCode } from '@/modules/me';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './field.module.scss';
import { AddPhoneModal, ConfirmNumberModal/* , ConfirmPasswordModal */ } from './modals';
import { getDays, MAX_DAYS } from './helpers';

type PhoneProps = {
  phoneNumber: string | null | undefined;
  refetch: () => void;
  phoneUpdatedAt: string | null | undefined;
  phoneVerified: boolean;
};

export const Phone = ({
  phoneNumber, refetch, phoneUpdatedAt, phoneVerified,
}: PhoneProps) => {
  const { t } = useTranslation();
  const { mutate: getCode } = useGetVerificationCode();
  const [isAddPhoneModalOpen, setIsAddPhoneModalOpen] = useState(false);
  const [isConfirmNumberModalOpen, setIsConfirmNumberModalOpen] = useState(false);
  // раскомментировать здесь и ниже, когда будет готова возможность запроса пароля для всех случаев
  // const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] = useState(false);
  // const [passwordModalMode, setPasswordModalMode] = useState<'phone_add' | 'phone_change'>('phone_change');
  const [number, setNumber] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);
  /* const {
    mutate: confirmPassword, isSuccess: isConfirmPasswordSuccess,
  } = useConfirmPassword(); */
  const { isMobile, isTablet } = useMatchMedia();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  /* const handleConfirmPassword = useCallback((psw: string) => {
    confirmPassword(psw);
  }, [confirmPassword]);

  useEffect(() => {
    if (isConfirmPasswordSuccess) {
      setIsConfirmPasswordModalOpen(false);
      setIsAddPhoneModalOpen(true);
    }
  }, [isConfirmPasswordSuccess]);

  const onConfirmPassword = useCallback((mode: 'phone_add' | 'phone_change') => {
    setPasswordModalMode(mode);
    setIsConfirmPasswordModalOpen(true);
  }, []);
  const onCloseConfirmPassword = useCallback(() => setIsConfirmPasswordModalOpen(false), []); */
  const onCloseAddPhoneModal = useCallback(() => setIsAddPhoneModalOpen(false), []);
  const onCloseConfirmModal = useCallback(() => setIsConfirmNumberModalOpen(false), []);
  const toggleModal = useCallback(() => setIsTipModalOpen((prev) => !prev), []);

  const onBack = useCallback(() => {
    onCloseConfirmModal();
    setIsAddPhoneModalOpen(true);
  }, []);

  const onPhoneContinue = useCallback((num: string) => {
    onCloseAddPhoneModal();
    setNumber(num);
    setIsConfirmNumberModalOpen(true);
  }, []);

  const onResend = useCallback(() => {
    getCode({ phone_number: number });
    setMinutes(2);
    setSeconds(0);
  }, [number]);

  const days = useMemo(
    () => (phoneUpdatedAt ? getDays(phoneUpdatedAt) : +MAX_DAYS),
    [phoneUpdatedAt],
  );
  const disabled = days ? days <= +MAX_DAYS : false;
  const daysLeft = useMemo(() => {
    if (days) {
      return getDeclension({
        count: days,
        t,
        singular: 'userAccount:days.one',
        few: 'userAccount:days.few',
        many: 'userAccount:days.many',
      });
    }
  }, [days, t]);

  return (
    <>
      <div className={clsx(s.contact_item, s.field)}>
        <p className={s.contact_header}>{t('auth:label.phoneNumber')}</p>
        {phoneNumber ? (
          <div className={clsx(s.email_contact, {
            [s.contact_verified]: disabled,
          })}
          >
            <div className={clsx(s.input_view, s.input_view_contacts, {
              [s.phone]: !disabled,
            })}
            >
              <SvgIcon name="phone" />
              <span>{formatPhoneNumber(phoneNumber.toString())}</span>
              {!disabled && (
                <span
                  className={s.edit_icon}
                  onClick={() => setIsAddPhoneModalOpen(true) /* onConfirmPassword('phone_change') */}
                >
                  <SvgIcon name="pencil" />
                </span>
              )}
            </div>
            {disabled && (
              (isMobile || isTablet) ? (
                <Button
                  appearance="secondary"
                  buttonColor="blue"
                  buttonSize="xs"
                  onClick={toggleModal}
                >
                  <SvgIcon name="info" className={s.info_svg} />
                </Button>
              ) : (
                <AppTooltip
                  title={t('userAccount:next_number_change', { daysLeft: `${days} ${daysLeft}` })}
                  stylesTooltip="gray"
                >
                  <span>
                    <SvgIcon name="info" className={s.info_svg} />
                  </span>
                </AppTooltip>
              )
            )}
          </div>
        ) : (
          <Button
            appearance="secondary"
            buttonColor="white"
            buttonSize="s"
            onClick={() => setIsAddPhoneModalOpen(true)/* onConfirmPassword('phone_add') */}
            className={s.add_contact}
          >
            <SvgIcon name="plus" />
            {t('userAccount:add_phone')}
          </Button>
        )}
      </div>
      {/* <ConfirmPasswordModal
        isOpen={isConfirmPasswordModalOpen}
        onClose={onCloseConfirmPassword}
        onClick={handleConfirmPassword}
        mode={passwordModalMode}
        days={MAX_DAYS}
      /> */}
      <AddPhoneModal
        isOpen={isAddPhoneModalOpen}
        onClose={onCloseAddPhoneModal}
        onContinue={onPhoneContinue}
        phoneNumber={phoneNumber}
        setMinutes={setMinutes}
        setSeconds={setSeconds}
        phoneVerified={phoneVerified}
      />
      <ConfirmNumberModal
        isOpen={isConfirmNumberModalOpen}
        onClose={onCloseConfirmModal}
        onBack={onBack}
        onResend={onResend}
        refetch={refetch}
        number={number}
        minutes={minutes}
        seconds={seconds}
        setMinutes={setMinutes}
        setSeconds={setSeconds}
      />
      <TipModal
        isOpen={isTipModalOpen}
        content={t('userAccount:next_number_change', { daysLeft: `${days} ${daysLeft}` })}
        onClose={toggleModal}
      />
    </>
  );
};
